import { loadTheme } from "./theme.js";
import { buildHeader } from "./header.js";
import { buildCards } from "./cards.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

addEventListener("DOMContentLoaded", (event) => {
    document.getElementById("copyright-year").innerText = new Date().getFullYear();

    let reanimate = document.getElementById("reanimate");
    // check if the element exists
    if (reanimate) {
        reanimate.addEventListener("click", buildHeader);
        reanimate.addEventListener("touchstart", (e) => {
            buildHeader();
            e.preventDefault();
        });
    }

    loadTheme();

    // Wait until the fonts are all loaded
    document.fonts.ready.then(() => {
        // Use the font to render the charts
        debugger
        buildHeader();
        buildCards();
    });

    // Initialize the agent on application start.
    const fpPromise = FingerprintJS.load({
        apiKey: "OSRRKAXLdOlQxyvG3zY8",
        endpoint: "https://i.andyraddatz.com",
        scriptUrlPattern: "https://i.andyraddatz.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js"
      })
    
    // Get the visitorId when you need it.
    fpPromise
      .then(fp => fp.get())
      .then(result => console.log(result.visitorId))
});